import { graphql, useStaticQuery } from 'gatsby';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsDriverType, CsQuestion } from 'types/contentStack';
import { DriverType, Question } from 'types/forms';

export type DriverConsentQuestions = {
  hasDriverConsent: Question;
};

type CsDriverConsentQuestions = {
  allCsAddDriverDriverConsentQuestionsTemplate: {
    nodes: { driver_type: CsDriverType; has_drivers_consent: CsQuestion }[];
  };
};

const query = graphql`
  query {
    allCsAddDriverDriverConsentQuestionsTemplate {
      nodes {
        driver_type
        has_drivers_consent {
          main_details {
            question_text
            explanatory_text
            tooltip {
              body
              button_text {
                open
                close
              }
            }
            alert_text
          }
        }
      }
    }
  }
`;

const useDriverConsentQuestions = (driverType: DriverType): DriverConsentQuestions => {
  const { allCsAddDriverDriverConsentQuestionsTemplate } = useStaticQuery<CsDriverConsentQuestions>(
    query
  );
  const { processQuestion } = useQuestionProcessor();

  const driverConsentQuestions = allCsAddDriverDriverConsentQuestionsTemplate.nodes.find(
    (questions) => questions.driver_type === driverType
  );

  /* istanbul ignore if */
  if (!driverConsentQuestions) {
    throw new Error(`About the driver questions missing for ${driverType}`);
  }

  return {
    hasDriverConsent: processQuestion(driverConsentQuestions.has_drivers_consent),
  };
};

export default useDriverConsentQuestions;
