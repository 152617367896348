import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { navigate } from 'gatsby';
import React from 'react';
import { useDriverConsent } from 'state/forms/driverConsent/action';
import { useReplaceDriver } from 'state/forms/replaceDriver/action';
import BooleanRadioInput from 'components/BooleanRadioInput';
import FormFooter from 'components/Forms/FormFooter';
import QuestionField from 'components/QuestionField';
import { isTemporaryDriver } from 'helpers/driverTypeHelper';
import { trackFieldError, trackNamedDriverFormContinue } from 'helpers/eventTracking';
import { formNameStepMapping } from 'helpers/formNameStepMapping';
import { scrollAndTrackError } from 'helpers/forms';
import { FormPage, getFormPageRoute } from 'helpers/routingHelper';
import { DriverType } from 'types/forms';
import useDriverConsentQuestions from './questions';
import useDriverConsentRules from './validation';

const DriverConsentForm: React.FC<{ driverType: DriverType }> = ({ driverType }) => {
  const [driverConsent, updateDriverConsent] = useDriverConsent();
  const rules = useDriverConsentRules(driverType);
  const questions = useDriverConsentQuestions(driverType);
  const { getError, validateOnSubmit } = useValidation(driverConsent, rules, trackFieldError);
  const [replaceDriverState] = useReplaceDriver();

  const handleSuccess = (): void => {
    trackNamedDriverFormContinue(
      {
        formPage: "Driver's consent",
        formStep: formNameStepMapping.driverConsent,
        driversConsent: driverConsent.hasDriverConsent,
      },
      replaceDriverState.replacedDriverRef
    );
    if (isTemporaryDriver(driverType)) {
      navigate(getFormPageRoute(driverType, FormPage.NewDriversCover));
    } else {
      navigate(getFormPageRoute(driverType, FormPage.AboutTheDriver));
    }
  };

  return (
    <form onSubmit={validateOnSubmit(handleSuccess, scrollAndTrackError)}>
      <QuestionField question={questions.hasDriverConsent} errorText={getError('hasDriverConsent')}>
        <BooleanRadioInput
          id="hasDriverConsent"
          value={driverConsent.hasDriverConsent}
          onChange={(newValue) => {
            updateDriverConsent({ hasDriverConsent: newValue });
          }}
        />
      </QuestionField>
      <FormFooter
        backButton={{
          onClick: () => navigate(getFormPageRoute(driverType, FormPage.ConfirmAddress)),
        }}
      />
    </form>
  );
};

export default DriverConsentForm;
