import { Location } from 'history';
import React from 'react';
import DriverConsentForm from 'components/Forms/DriverConsent';
import FormHeading from 'components/Forms/FormHeading';
import FormPageWrapper from 'components/Forms/FormPageWrapper';
import Layout from 'components/Layout';
import { useFormRedirect } from 'helpers/useFormRedirect';
import { DriverType } from 'types/forms';

export type DriverConsentData = {
  meta_title: string;
  heading: string;
};

type DriverConsentProps = {
  data: DriverConsentData;
  location: Location;
  driverType: DriverType;
};

const DriverConsentBaseComponent: React.FC<DriverConsentProps> = ({
  data,
  location,
  driverType,
}) => {
  useFormRedirect();
  return (
    <FormPageWrapper
      location={location}
      title={data.meta_title}
      formPageName="driverConsent"
      driverType={driverType}>
      <Layout pageType="form" meta={{ title: data.meta_title }}>
        <FormHeading text={data.heading} />
        <DriverConsentForm driverType={driverType} />
      </Layout>
    </FormPageWrapper>
  );
};

export default DriverConsentBaseComponent;
