import { required } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { DriverConsentState } from 'state/forms/driverConsent/state';
import { CsDriverType } from 'types/contentStack';
import { DriverType } from 'types/forms';

type CsDriverDetailsErrorMessages = {
  allCsAddDriverDriverConsentQuestionsTemplate: {
    nodes: {
      driver_type: CsDriverType;
      has_drivers_consent: {
        error_messages: {
          missing: string;
          no_consent: string;
        };
      };
    }[];
  };
};

const query = graphql`
  query {
    allCsAddDriverDriverConsentQuestionsTemplate {
      nodes {
        driver_type
        has_drivers_consent {
          error_messages {
            missing
            no_consent
          }
        }
      }
    }
  }
`;

const useDriverConsentRules = (driverType: DriverType): ValidationRules<DriverConsentState> => {
  const errorMessages = useStaticQuery<CsDriverDetailsErrorMessages>(query);
  const driverConsentQuestions = errorMessages.allCsAddDriverDriverConsentQuestionsTemplate.nodes.find(
    (questions) => questions.driver_type === driverType
  );

  /* istanbul ignore if */
  if (!driverConsentQuestions) {
    throw new Error(`Driver consent questions missing for ${driverType}`);
  }

  return {
    hasDriverConsent: [
      required(driverConsentQuestions.has_drivers_consent.error_messages.missing),
      {
        validityCondition: (_, data) => data.hasDriverConsent === true,
        errorMessage: driverConsentQuestions.has_drivers_consent.error_messages.no_consent,
      },
    ],
  };
};

export default useDriverConsentRules;
